import React, { useState } from "react";
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { useAuth } from '../components/Firebase';

import SEO from "../components/seo"
import PageTitle from '../components/PageTitle';

function SigninPage() {
    const baseState = {email: '', password: ''}

    const [formValues, setFormValues] = useState(baseState);
    const [error, setError] = useState(false);
    const { firebase } = useAuth();

    function handleSubmit(event) {
        event.preventDefault();

        firebase.login({email: formValues.email, password: formValues.password})
        .then(
            result => navigate('/members'),
            error => {
                setError(true)
                setFormValues(baseState)
            }
        );
    }

    function handleInputChange(event) {
        event.persist();
        setFormValues(currentValues => ({
                ...currentValues,
                [event.target.name]: event.target.value
            })
        );
    }

    return (
        <>
            <SEO title="Sign In" />
            <CENTER>
                <PageTitle title="Sign In"/>
                {/* <P>Please Sign In</P> */}
                <BOX>
                    {error && <ERROR>Incorrect password. Please try again or email support to reset your password.</ERROR>}
                    <FORM onSubmit={handleSubmit}>
                        <INPUT value={formValues.email} name='email' onChange={handleInputChange} placeholder='email' type='email' />
                        <INPUT value={formValues.password} name='password' onChange={handleInputChange} placeholder='password' type="password" />
                        <BUTTON type="submit">Sign In</BUTTON>
                    </FORM>
                </BOX>
            </CENTER>
        </>
    );
}

const CENTER = styled('main')`
  width: 100vw;
  margin: 0 auto;
  background: #FFF;

	// image switches to fullscreen
	@media (max-width: 500px) {
		width: 100%;
  }
`;

const ERROR = styled('p')`
    width: 50%;
    margin: 0 auto;
    padding: 0 5px;
    text-align: center;
    color: red;
`;

// const P = styled('p')`
//     font-size: 20px;
//     width: 80%;
//     text-align: center;
//     padding-bottom: 80px;
//     margin: 0 auto;
//     font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

//     @media (max-width: 500px) {
//       padding-left: 50px;
//       padding-right: 50px;
//       font-size: 15px !important;
//     }
// `;

const BOX = styled('div')`
    padding-bottom: 50px;
`;

// copied from Email Subscribe
// const HEADER = styled('div')`
//     font-size: 50px;
//     font-weight: 600;
//     line-height: 1;
//     max-width: 640px;
//     margin: 0 auto 20px auto;
//     font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
// `;

// const COPY = styled('p')`
//     max-width: 640px;
//     font-size: 28px;
//     font-weight: 600;
//     font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
//     line-height: 1.4;
//     margin: 0 auto 20px auto;

//     @media (max-width: 700px) {
//         font-size: 20px;
//         padding: 0 30px;
//         text-align: center;
//     }
// `;

const FORM = styled('form')`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
`;

const INPUT = styled('input')`
    height: 52px;
    background: #f3f3f3;
    border-radius: 16px;
    border: medium none;
    font-size: 18px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    outline: currentcolor none 0px;
    padding: 0px 20px;
    margin: 10px 0;
    transition: box-shadow 0.2s ease 0s, color 0.2s ease 0s;
    ${'' /* width: 100%; */}
    width: 300px;

    &:focus {
        box-shadow: inset 0 0 0 1px #09f;
        outline-width: 0px;
}
    }

    &::placeholder {
        color: #bababa;
    }
`;

const BUTTON = styled('button')`
    background: #09f;
    color: #fff;
    position: relative;
    appearance: none;
    text-decoration: none;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -0.015em;
    display: inline-flex;
    flex-direction: row;
    place-items: center;
    place-content: center;
    height: 52px;
    width: 300px;
    padding: 0px 20px;
    border-radius: 16px;
    margin: 10px 0;
    ${'' /* box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1),0px 2px 10px 0px rgba(0,0,0,0.08)); */}
    border: medium none;
    cursor: pointer;
    outline: currentcolor none medium;
    transition-duration: 0.2s;
    transition-property: background, color, opacity, box-shadow;

    &:hover {
        background: #07f;
    }

    @media (max-width: 550px) {
        margin-top: 10px;
    }
`;

export default SigninPage;